// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-tsx": () => import("/Users/sakihayashi/Documents/CodeImmersives/gatsby/musicofsnow_20191017/src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("/Users/sakihayashi/Documents/CodeImmersives/gatsby/musicofsnow_20191017/src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-templates-author-tsx": () => import("/Users/sakihayashi/Documents/CodeImmersives/gatsby/musicofsnow_20191017/src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/sakihayashi/Documents/CodeImmersives/gatsby/musicofsnow_20191017/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/Users/sakihayashi/Documents/CodeImmersives/gatsby/musicofsnow_20191017/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("/Users/sakihayashi/Documents/CodeImmersives/gatsby/musicofsnow_20191017/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/sakihayashi/Documents/CodeImmersives/gatsby/musicofsnow_20191017/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("/Users/sakihayashi/Documents/CodeImmersives/gatsby/musicofsnow_20191017/src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/sakihayashi/Documents/CodeImmersives/gatsby/musicofsnow_20191017/.cache/data.json")

